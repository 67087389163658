import React, { Fragment } from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { push, Push } from 'connected-react-router';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, notification } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import TransactionReport from './ReportType/TransactionsReport';
import OnlyDateReport from './ReportType/OnlyDateReport';
import StartEndDateReport from './ReportType/DisbursementReport';
import MonthlyReport from './ReportType/MonthlyReport';
import StartEndDateTrcReport from './ReportType/PaymentReport';
import { API_URL, postJson } from '../../../helpers/api/api';
import UpdateCreditreportReport from './ReportType/UpdateCreditreportReport';
import { iso8601ToLocalDate, toYYYYMM } from '../../../helpers/date/date';

interface Props extends FormComponentProps {
  push: Push;
  projectId: string;
}

const reportIndex: React.FC<Props> = ({ form, projectId }) => {
  const { Option } = Select;
  const { getFieldValue, getFieldDecorator } = form;
  const reportName = getFieldValue('reportName');
  const allowNcbProjects = ['760296', '173880', '722683', '332432', '640579', '649571'];

  const handleSubmitReport = () => {
    form.validateFields(async (err: any, values: any) => {
      if (err) {
        notification.error({
          message: 'Please check required fields',
        });
        return;
      }
      try {
        const formatValue = (values: any) => {
          const startDate = values.startDate ? iso8601ToLocalDate(values.startDate) : undefined;
          const endDate = values.endDate ? iso8601ToLocalDate(values.endDate) : undefined;
          const asOfDate = values.asOfDate ? iso8601ToLocalDate(values.asOfDate) : undefined;
          const asOfMonth = values.asOfMonth ? toYYYYMM(values.asOfMonth) : undefined;
          const startMonth = values.startMonth ? toYYYYMM(values.startMonth) : undefined;
          const endMonth = values.endMonth ? toYYYYMM(values.endMonth) : undefined;

          return {
            ...values,
            startDate,
            endDate,
            asOfDate,
            asOfMonth,
            startMonth,
            endMonth,
          };
        };
        const formatted = formatValue(values);
        await postJson(`${API_URL}/projects/${projectId}/reports/${reportName}`, formatted);

        notification.success({
          message: 'Generate report successful',
          description: 'Please check report in email',
        });
      } catch (error) {
        notification.error({
          message: 'Cannot Generate report',
        });
      }
    });
  };

  return (
    <div>
      <div className="card">
        <div className="card-header">
          <div className="utils__title">
            <strong>
              <FormattedMessage id="form.header.report" />
            </strong>
          </div>
        </div>
        <div className="card-body">
          <Form>
            <Form.Item label={<FormattedMessage id="report.name" />}>
              {getFieldDecorator('reportName', {
                rules: [
                  {
                    required: true,
                    message: 'please select report name',
                  },
                ],
              })(
                <Select>
                  <Option value="transaction">Transaction</Option>
                  <Option value="debt">Debt</Option>
                  <Option value="disbursement">Disbursement</Option>
                  <Option value="payment">Payment</Option>
                  <Option value="dailyPayment">Daily-Payment</Option>
                  <Option value="incomeForecast">Income-Forecast</Option>
                  <Option value="closedLoan">Closed-Loan</Option>
                  <Option value="stampFee">Stamp-Fee</Option>
                  <Option value="bot">Bot</Option>
                  <Option value="portfolio">Portfolio</Option>
                  <Option value="newloan">New-Loan</Option>
                  <Option value="collectionRepay">Collection-Repay</Option>
                  <Option value="collectionLoan">Collection-Loan</Option>
                  <Option value="collectionStatus">Collection-Status</Option>
                  <Option value="delinquency">Delinquency</Option>
                  <Option value="pt40">ภ.ธ. 40</Option>
                  <Option value="pt40plus">
                    ภ.ธ. 40 (รวมรายได้จากค่าธรรมเนียมและหนี้สูญรับคืน)
                  </Option>
                  <Option value="ssk">สศค.</Option>
                  <Option value="os4kh">อส4ข</Option>
                  <Option value="os9">อส9</Option>
                  {allowNcbProjects.includes(projectId) && (
                    <>
                      <Option value="ncb">[NCB] รายงานรายเดือน</Option>
                      <Option value="new-ncb-customer">[NCB] รายงานลูกค้าใหม่</Option>
                      <Option value="update-ncb-creditreport">
                        [NCB] แก้ไขข้อมูล datasubmission รายคน
                      </Option>
                    </>
                  )}
                  <Option value="botploan">สินเชื่อส่วนบุคคลภายใต้กำกับ (ธปท.)</Option>
                  <Option value="botnano">สินเชื่อรายย่อยเพื่อการประกอบอาชีพ (ธปท.)</Option>
                  <Option value="notice">หนังสือบอกกล่าวทวงถาม</Option>
                  <Option value="account_portfolio">[บัญชี] ลูกหนี้คงเหลือ</Option>
                  <Option value="account_transaction">[บัญชี] รายการเคลื่อนไหว</Option>
                  <Option value="botplo">ธปท.(plo)</Option>
                  <Option value="botplc">ธปท.(plc)</Option>
                  <Option value="botplu">ธปท.(plu)</Option>
                </Select>,
              )}
            </Form.Item>
          </Form>
          {reportName === 'transaction' && (
            <Fragment>
              <TransactionReport form={form} handleSubmitReport={handleSubmitReport} />
            </Fragment>
          )}
          {reportName === 'debt' && (
            <Fragment>
              <OnlyDateReport form={form} handleSubmitReport={handleSubmitReport} />
            </Fragment>
          )}
          {reportName === 'disbursement' && (
            <Fragment>
              <StartEndDateReport form={form} handleSubmitReport={handleSubmitReport} />
            </Fragment>
          )}
          {reportName === 'payment' && (
            <Fragment>
              <StartEndDateTrcReport form={form} handleSubmitReport={handleSubmitReport} />
            </Fragment>
          )}
          {reportName === 'dailyPayment' && (
            <Fragment>
              <StartEndDateReport form={form} handleSubmitReport={handleSubmitReport} />
            </Fragment>
          )}
          {reportName === 'incomeForecast' && (
            <Fragment>
              <OnlyDateReport form={form} handleSubmitReport={handleSubmitReport} />
            </Fragment>
          )}
          {reportName === 'closedLoan' && (
            <Fragment>
              <StartEndDateReport form={form} handleSubmitReport={handleSubmitReport} />
            </Fragment>
          )}
          {reportName === 'stampFee' && (
            <Fragment>
              <StartEndDateReport form={form} handleSubmitReport={handleSubmitReport} />
            </Fragment>
          )}
          {reportName === 'bot' && (
            <Fragment>
              <StartEndDateReport form={form} handleSubmitReport={handleSubmitReport} />
            </Fragment>
          )}
          {reportName === 'portfolio' && (
            <Fragment>
              <OnlyDateReport form={form} handleSubmitReport={handleSubmitReport} />
            </Fragment>
          )}
          {reportName === 'newloan' && (
            <Fragment>
              <StartEndDateReport form={form} handleSubmitReport={handleSubmitReport} />
            </Fragment>
          )}
          {reportName === 'collectionRepay' && (
            <Fragment>
              <StartEndDateReport form={form} handleSubmitReport={handleSubmitReport} />
            </Fragment>
          )}
          {reportName === 'collectionLoan' && (
            <Fragment>
              <OnlyDateReport form={form} handleSubmitReport={handleSubmitReport} />
            </Fragment>
          )}
          {reportName === 'delinquency' && (
            <Fragment>
              <StartEndDateReport form={form} handleSubmitReport={handleSubmitReport} />
            </Fragment>
          )}
          {reportName === 'pt40' && (
            <MonthlyReport form={form} handleSubmitReport={handleSubmitReport} />
          )}
          {reportName === 'pt40plus' && (
            <MonthlyReport form={form} handleSubmitReport={handleSubmitReport} />
          )}
          {reportName === 'ssk' && (
            <MonthlyReport form={form} handleSubmitReport={handleSubmitReport} />
          )}
          {reportName === 'os4kh' && (
            <Fragment>
              <StartEndDateReport form={form} handleSubmitReport={handleSubmitReport} />
            </Fragment>
          )}
          {reportName === 'os9' && (
            <Fragment>
              <StartEndDateReport form={form} handleSubmitReport={handleSubmitReport} />
            </Fragment>
          )}
          {reportName === 'ncb' && (
            <MonthlyReport form={form} handleSubmitReport={handleSubmitReport} />
          )}
          {reportName === 'new-ncb-customer' && (
            <MonthlyReport form={form} handleSubmitReport={handleSubmitReport} />
          )}
          {reportName === 'update-ncb-creditreport' && (
            <UpdateCreditreportReport form={form} handleSubmitReport={handleSubmitReport} />
          )}
          {reportName === 'botploan' && (
            <Fragment>
              <MonthlyReport form={form} handleSubmitReport={handleSubmitReport} />
            </Fragment>
          )}
          {reportName === 'botnano' && (
            <Fragment>
              <MonthlyReport form={form} handleSubmitReport={handleSubmitReport} />
            </Fragment>
          )}
          {reportName === 'notice' && (
            <Fragment>
              <OnlyDateReport form={form} handleSubmitReport={handleSubmitReport} />
            </Fragment>
          )}
          {reportName === 'account_portfolio' && (
            <Fragment>
              <OnlyDateReport form={form} handleSubmitReport={handleSubmitReport} />
            </Fragment>
          )}
          {reportName === 'account_transaction' && (
            <Fragment>
              <StartEndDateReport form={form} handleSubmitReport={handleSubmitReport} />
            </Fragment>
          )}
          {reportName === 'collectionStatus' && (
            <Fragment>
              <OnlyDateReport form={form} handleSubmitReport={handleSubmitReport} />
            </Fragment>
          )}
          {reportName === 'botplo' && (
            <Fragment>
              <MonthlyReport form={form} handleSubmitReport={handleSubmitReport} />
            </Fragment>
          )}
          {reportName === 'botplc' && (
            <Fragment>
              <MonthlyReport form={form} handleSubmitReport={handleSubmitReport} />
            </Fragment>
          )}
          {reportName === 'botplu' && (
            <Fragment>
              <MonthlyReport form={form} handleSubmitReport={handleSubmitReport} />
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

const reprotFrom = Form.create<Props>()(reportIndex);

const mapStateToProps = (state: any) => {
  return {
    projectId: state.user.currentProjectId,
  };
};

export default connect(mapStateToProps, { push })(reprotFrom);
